import React, { FC, useEffect, useRef } from "react";
import { ContactSectionWrapper } from "./index.styled";
import ContactBody from "../contact-body";
const ContactSection: FC = () => {
  const contactSectionWrapperRef = useRef<HTMLDivElement>(null);

  return (
    <ContactSectionWrapper ref={contactSectionWrapperRef} id={"contact"}>
      <ContactBody />
    </ContactSectionWrapper>
  );
};

export default ContactSection;
