import React, { FC, useEffect, useState } from "react";
import FormLogic from "./formLogic";
import {
  FormButton,
  FormInput,
  FormItem,
  FormArea,
  FormRadioInput,
  FormRadioItem,
  FormRadioText,
  FormRadioWrapper,
  FormWrapper,
  FormDD,
  SelectBtn,
  SelectContent,
  SelectItem
} from "./index.styled";
interface IProps {
  showSuccess: () => void;
}

const ContactForm: FC<IProps> = ({ showSuccess }) => {
  const [comments, setComments] = useState("");
  const [isHowHearActive, setIsHowHearActive] = useState(false);
  const [isHowContactActive, setIsHowContactActive] = useState(false);
  const [howHearSelected, setHowHearSelected] = useState("");
  const [countrySelected, setCountrySelected] = useState("");
  const [countryIndex, setCountryIndex] = useState("");
  const hclist = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo",
    "Congo, the Democratic Republic of the",
    "Cook Islands",
    "Costa Rica",
    "Cote D'",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Falkland Islands (Malvinas)",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Holy See (Vatican City State)",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran, Islamic Republic of",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea, Democratic People's Republic ",
    "Korea, Republic of",
    "Kuwait",
    "Kyrgyzstan",
    "Lao People's Democratic ",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libyan Arab Jamahiriya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Macedonia, the Former Yugoslav Republic of",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia, Federated States of",
    "Moldova, Republic of",
    "Monaco",
    "Mongolia",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "Netherlands Antilles",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Reunion",
    "Romania",
    "Russian Federation",
    "Rwanda",
    "Saint Helena",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Svalbard and Jan Mayen",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Republic of China, Taiwan",
    "Tajikistan",
    "Tanzania, United Republic of",
    "Thailand",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States of America (USA)",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela",
    "Viet Nam",
    "Virgin Islands, British",
    "Virgin Islands, U.s",
    "Wallis and Futuna",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe"
  ];
  const hhlist = [
    "Google Search",
    "Broker",
    "Eblast",
    "Social Media",
    "Site Signage",
    "Art Basel Magazine",
    "Boca Raton Observer",
    "Fort Lauderdale Illustrated",
    "Island Magezine",
    "Lifestyle Magazine",
    "Miami Magazine",
    "Ocean Drive Magazine",
    "The Real Deal",
    "WSJ Mansion Global",
    "1926 Mizner's Dream",
    "Venice Magazine",
    "Marriott Bonvoy",
    "Herein Magazine",
    "Zillow",
    "New York Post",
  ];
  const { inputs, handleChange, handleSubmit, handleBlur, errors, submitted } =
    FormLogic();

  useEffect(() => {
    if (submitted) showSuccess();
  }, [submitted]);

  useEffect(() => {
    inputs.howhear = howHearSelected;
    inputs.country = countryIndex;
    inputs.comments = comments;
  }, [howHearSelected, countryIndex, comments]);

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <FormItem className={"broker"}>
        <FormRadioWrapper>
          <span>I am a </span>
          <FormRadioItem>
            <FormRadioInput
              type="radio"
              name="isbroker"
              value="false"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <FormRadioText error={errors.isbroker}>Buyer</FormRadioText>
          </FormRadioItem>
          <FormRadioItem>
            <FormRadioInput
              type="radio"
              name="isbroker"
              value="true"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <FormRadioText error={errors.isbroker}>Broker</FormRadioText>
          </FormRadioItem>
        </FormRadioWrapper>
      </FormItem>
      <FormItem>
        <FormInput
          type="text"
          name="fname"
          onChange={handleChange}
          value={inputs.fname}
          error={(errors && errors.fname && errors.fname) || false}
          onBlur={handleBlur}
          style={{ textTransform: "capitalize" }}
        />
        <label htmlFor="fname">First Name*</label>
      </FormItem>
      <FormItem>
        <FormInput
          type="text"
          name="lname"
          onChange={handleChange}
          value={inputs.lname}
          error={errors.lname}
          onBlur={handleBlur}
          style={{ textTransform: "capitalize" }}
        />
        <label htmlFor="lname">Last Name*</label>
      </FormItem>
      <FormItem>
        <FormInput
          type="email"
          name="email"
          value={inputs.email}
          error={errors.email}
          onChange={handleChange}
        />
        <label htmlFor="email">Email Address*</label>
      </FormItem>
      <FormItem>
        <FormInput
          type="tel"
          name="phone"
          onChange={handleChange}
          value={inputs.phone}
          error={errors.phone}
        // onBlur={handleBlur}
        />
        <label htmlFor="phone">Phone number*</label>
      </FormItem>
      <FormItem>
        <FormInput
          type="text"
          name="city"
          onChange={handleChange}
          value={inputs.city}
          error={errors.city}
        />
        <label htmlFor="city">City*</label>
      </FormItem>
      <FormItem>
        <FormInput
          type="text"
          name="state"
          error={errors.state}
          onChange={handleChange}
          value={inputs.state}
        />
        <label htmlFor="state">State*</label>
      </FormItem>
      <FormItem className={'country'}>
        <FormDD>
          <span>Country*</span>
          <SelectBtn
            error={!!errors.country}
            onClick={() => {
              setIsHowContactActive(!isHowContactActive)
              setIsHowHearActive(false);
            }}
            className={isHowContactActive ? "active" : ""}
          >
            {countrySelected}
          </SelectBtn>
          <SelectContent className={isHowContactActive ? "active" : ""}>
            {hclist.map((item, i) => (
              <SelectItem
                className={(item === "United States of America (USA)") ? 'order-1' : ''}
                key={i}
                onClick={() => {
                  setCountrySelected(item);
                  setCountryIndex((i + 1).toString())
                  setIsHowContactActive(!isHowContactActive);
                }}
              >
                {item}
              </SelectItem>
            ))}
          </SelectContent>
        </FormDD>
      </FormItem>
      <FormItem className={"comments"}>
        <label htmlFor="state">Comments:</label>
        <FormArea
          value={comments}
          onChange={(e) => setComments(e.target.value)}
        />
      </FormItem>
      <FormItem className={"howhear"}>
        <FormDD>
          <span>How did you hear about us*</span>
          <SelectBtn
            error={!!errors.howhear}
            onClick={() => {
              setIsHowHearActive(!isHowHearActive)
              setIsHowContactActive(false)
            }}
            className={isHowHearActive ? "active" : ""}
          >
            {howHearSelected}
          </SelectBtn>
          <SelectContent className={isHowHearActive ? "active" : ""}>
            {hhlist.map((item, i) => (
              <SelectItem
                key={i}
                onClick={() => {
                  setHowHearSelected(item);
                  setIsHowHearActive(!isHowHearActive);
                }}
              >
                {item}
              </SelectItem>
            ))}
          </SelectContent>
        </FormDD>
      </FormItem>

      <FormItem className={'submit'}>
        <FormButton type="submit" value="Submit">Submit</FormButton>
      </FormItem>
    </FormWrapper>
  );
};

export default ContactForm;
