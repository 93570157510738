import React, {useContext, useEffect}  from "react";
import { seoData } from "../data/seo";
import Seo from "../components/seo";
import Footer from "../components/footer";
import ContactSection from "../components/contact/contact-section";
import NewComposition from "../components/composition/test";
import { CompositionWrapper } from "../components/composition/test/styled.index";
import {Context} from "../context";
import useWindowSize from "../helpers/UseWindowSize";

const ContactPage = () => {
  const {width} = useWindowSize();
  const { setIsMenuLight } = useContext(Context);
  if (typeof window === `undefined`) {
    return(<></>);
  }

  useEffect(()=> {
    setIsMenuLight(false)
  })
  return (
    <main>
      <Seo page={seoData.contact} />
      <CompositionWrapper>
        <ContactSection />
        <Footer />
        {width > 1023 && <NewComposition shape={"circle"} top={"-10px"} right={"92vw"} height={'40vw'}/>}
        {width > 1023 && <NewComposition shape={"triangle"}  bottom={"0"} right={"0"} height={width > 1024 ? "75vh" : "50vh"}/>}
      </CompositionWrapper>
    </main>
  );
};

export default ContactPage;
