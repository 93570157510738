import React, { useState } from "react";
import validateForm, { IErrors } from "./validateForm";

const FormLogic = () => {
  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const [submitted, setFormSubmitted] = useState(false);
  const [inputs, setInputs] = useState<any>({
    fname: "",
    lname: "",
    phone: "",
    email: "",
    city: "",
    state: "",
    zipcode: "",
    howhear: "",
    country: "",
    comments: null,
    isbroker: null,
  });
  const [errors, setErrors] = useState<IErrors>({
    fname: false,
    lname: false,
    email: false,
    phone: false,
    state: false,
    city: false,
    isbroker: false,
    howhear: false,
  });

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const newErrors = validateForm(inputs);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      const formInputs: any = {
        "fname": capitalizeFirstLetter(inputs.fname),
        "lname": capitalizeFirstLetter(inputs.lname),
        "email": inputs.email,
        "phone": inputs.phone,
        "city": inputs.city,
        "state": inputs.state,
        "province": inputs.state,
        "comments": inputs.comments,
        "howhear": inputs.howhear,
        "country": inputs.country,
        "isbroker": inputs.isbroker,
      };

      fetch("/contact-form", {
        method: "POST",
        body: JSON.stringify(formInputs),
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(() => {
          // console.log("success sending data", result);
          setFormSubmitted(true);
          setInputs({
            fname: "",
            lname: "",
            phone: "",
            email: "",
            city: "",
            state: "",
            comments: "",
            isbroker: "",
            howhear: "",
            country: "",
          });
        })
        .catch((error) => {
          console.log("error sending data", error);
        });
    }
  };

  const handleChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    event.persist();
    setInputs((oldInputs: any) => ({
      ...oldInputs,
      // @ts-ignore
      [event.target.name]: event.target.value,
    }));
  };

  const handleBlur = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const newErrors = validateForm(inputs, e.target.name);
    setErrors(newErrors);
  };

  return {
    handleSubmit,
    handleChange,
    inputs,
    submitted,
    handleBlur,
    errors,
  };
};

export default FormLogic;
