import { mediaMax, mediaMaxTablet, mediaMin } from "../../../../helpers/MediaQueries";
import styled from "styled-components";
import { cssLock } from "../../../../helpers/Mixins";

export const FormWrapper = styled.form`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 940px;
  color: #222;
  font-weight: 400 !important;
  letter-spacing: normal !important;
  ${cssLock({
    minSize: 13,
    maxSize: 16,
    minWidth: 375,
    maxWidth: 1920,
    property: "font-size",
    unit: "px",
  })};
  ${mediaMax.tabletLandscape`
    max-width: 700px;
  `};
  ${mediaMax.tabletM`
    max-width: 600px;
  `};
`;
export const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(50% - 20px);
  margin-bottom: 16px;
  ${mediaMax.tabletM`
    width: 76%;
    margin: 0 auto;
  `}
  &.submit {
    width: 100%;
    button {
      padding: 12px 0;
    }
    ${mediaMax.tabletM`
      width: 76%;
      padding-top: 16px;
      order: 3;
      button {
        margin: 0 auto;
      }
    `}
  }
  &.comments {
    label {
      margin-bottom: 10px;
    }
    ${mediaMax.tabletM`
      margin-top: 10px;
      order: 2;
    `}
  }
  &.howhear {
    margin-top: -96px;
    ${mediaMax.phoneXL`
      margin-top: 0;
    `}
  }
  &.country {
    ${mediaMax.phoneXL`
      margin-top: 26px;
      .active {
        max-height: 280px;
      }
    `}
  }
  &.broker {
    width: 100%;
    align-self: baseline;
    label {
      display: inline-block;
    }
    ${mediaMax.phoneXL`
      width: 76%;
    `}
  }
  &.mb-24 {
    margin-bottom: 24px;
  }

  &.mb-32 {
    margin-bottom: 32px;
  }

  &.full-width {
    width: 100%;
  }
`;
export const FormLabel = styled.label`
  text-align: center;
  letter-spacing: 1.4px;
  color: #000;
  text-transform: uppercase;
  margin: 0 0 8px;
`;

export const FormInput = styled.input`
  background: transparent;
  border: none;
  border-bottom: 1px solid
    ${(props: { error?: boolean }) => (props.error ? "red" : "#707070")};
  outline: none;
  padding: 4px;
  letter-spacing: 1.4px;
  color: #000;
  opacity: 1;

  -webkit-box-shadow: inset 0 0 0 50px #fff;
  -webkit-text-fill-color: #000;
  ${cssLock({
    minSize: 13,
    maxSize: 14,
    minWidth: 375,
    maxWidth: 1920,
    property: "font-size",
    unit: "px",
  })};
`;
export const FormSelect = styled.select`
  background: transparent;
  border: none;
  border-bottom: 1px solid #707070;
  text-align: center;
  letter-spacing: 1.4px;
  color: rgba(96, 96, 96, 1);
  opacity: 1;
  text-transform: uppercase;
  outline: none;
  padding: 0 4px;
  position: relative;
  width: 100%;
  appearance: none;
  text-align-last: center;

  ${mediaMaxTablet.tabletLandscape`
      padding: 4px 12px;
  `}
`;

export const FormButton = styled.button`
  background: #c9c3b8;
  outline: none;
  border: none;
  padding: 7px 12px 7px 15px;
  text-transform: uppercase;
  margin: 0 0 8px auto;
  transition: 0.3s;
  cursor: pointer;
  width: 100%;
  text-align: center;
  letter-spacing: 1.4px;
  color: #efeeea;
  word-wrap: revert;
  max-width: 126px;
  font-weight: 300;
  ${mediaMax.tablet`
      padding: 4px 12px;
  `}
  ${mediaMaxTablet.tabletLandscape`
      padding: 4px 12px;
  `}
  &:hover {
    ${mediaMin.tabletLandscape`
      background: #efeeea;
      color: #292929;
    `}

  }
`;

export const FormRadioWrapper = styled.div`
  display: flex;
  align-self: baseline;
  span {
    margin-right: 20px;
  }
`;
export const FormRadioItem = styled.label`
  margin-right: 2px;
`;
export const FormArea = styled.textarea`
  height: 146px;
  background-color: #f1efeb;
  border: none;
  resize: none;
  padding: 8px 12px;
  ${cssLock({
    minSize: 13,
    maxSize: 14,
    minWidth: 375,
    maxWidth: 1920,
    property: "font-size",
    unit: "px",
  })};
`;
export const FormRadioInput = styled.input`
  display: none;
  background: transparent;
  opacity: 1;

  &:checked + span {
    opacity: 1;
    :before {
      background: #292929;
    }
  }
`;
export const FormRadioText = styled.span`
  padding-left: 26px;
  position: relative;
  cursor: pointer;
  letter-spacing: 1.4px;
  color: #222;

  &::before {
    content: "";
    width: 18px;
    height: 18px;
    display: block;
    transition: all 0.2s ease-in-out 0s;
    margin-right: 8px;
    box-shadow: ${(props: { error?: boolean }) =>
        props.error ? "red" : "#ccc"}
      0 0 0 1px inset;
    flex: 0 0 22px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    opacity: 1;
  }
`;
export const FormDD = styled.div<{isError?: boolean}>`
  width: 100%;
  margin: 0 auto 14px;
  text-align: left;
  border: ${({isError}) => isError && '1px solid red'};
  position: relative;
  span {
    display: inline-block;
    margin-bottom: 10px;
  }
  ${mediaMax.tablet`
    margin-top: 10px;
  `}
`;
export const SelectBtn = styled.div`
  letter-spacing: 1.4px;
  color: #222;
  cursor: pointer;
  border: none;
  outline: none;
  border-bottom: 1px solid
    ${(props: { error?: boolean }) => (props.error ? "red" : "#EFEEEA")};
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 22px;
  background-color: #f1efeb;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    right: 18px;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #222;
    transition: 0.25s ease-out;
  }
  &.active:after {
    transform: rotate(-180deg);
    top: 43%;
  }
`;
export const SelectContent = styled.div`
  width: 100%;
  max-height: 0;
  height: max-content;
  opacity: 0;
  padding: 0;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  overflow: auto;
  cursor: pointer;
  transition: 0.4s ease-out;
  border: 1px rgba(36, 39, 41, 0.18);
  background-color: #f1efeb;
  color: rgba(36, 39, 42, 0.65);
  text-align: left;
  text-transform: capitalize;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  &.active {
    max-height: 23vh;
    opacity: 1;
    padding: 16px 0;
  }
  p {
    padding: 4px 12px;
    transition: 0.25s ease-out;

    &:hover {
      background-color: #C9C3B8;
      color: #fff;
    }
  }
`;

export const FormLogos = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 40px 40px 0;

  img {
    width: 124px;
    margin: 20px;
  }
  ${mediaMax.tablet`
    flex-direction: column;
    img {
      margin: 0 0 12px 0;
    }
  `};

`;

export const SelectItem = styled.p`
  padding: 4px 0;
  &.order-1 {
    order: -1;
  }
`;
