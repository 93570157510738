export interface IErrors {
  fname?: boolean;
  lname?: boolean;
  email?: boolean;
  phone?: boolean;
  isbroker?: boolean;
  howhear?: boolean;
  country?: boolean;
  state?: boolean;
  city?: boolean;  
}

interface IValues {
  fname?: string;
  lname?: string;
  email?: string;
  phone?: string;
  isbroker?: string;
  howhear?: string;
  country?: string;
  state?: string;
  city?: string; 
}

const validateForm = (values: IValues, targetName?: string) => {
  const errors: IErrors = {};
  const { email, fname, lname, phone, isbroker, howhear, country, city, state } = values;

  const checkFirstName = () => {
    if (!fname) {
      errors.fname = true;
    }
  };

  const checkCity = () => {
    if (!city) {
      errors.city = true;
    }
  }; 

 const checkState = () => {
    if (!state) {
      errors.state = true;
    }
  };

  const checkCountry = () => {
    if(!country) {
      errors.country = true
    }
  }

  const checkLastName = () => {
    if (!lname) {
      errors.lname = true;
    }
  };
  const checkPhone = () => {
    if (!phone) {
      errors.phone = true;
    }
  };
  const checkIsBroker = () => {
    if (!isbroker) {
      errors.isbroker = true;
    }
  };
  const checkHowHear = () => {
    if (howhear === "*HOW DID YOU HEAR ABOUT US?" || !howhear) {
      errors.howhear = true;
    }
  };
  const checkEmail = () => {
    if (!email) {
      errors.email = true;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      errors.email = true;
    }
  };

  if (!targetName) {
    checkPhone();
    checkEmail();
    checkFirstName();
    checkLastName();
    checkIsBroker();
    checkHowHear();
    checkCountry();
    checkCity();
    checkState();
  }
  if (targetName === "email") {
    checkEmail();
  }
  if (targetName === "state") {
    checkState();
  }
  if (targetName === "city") {
    checkCity();
  }
  if (targetName === "fname") {
    checkFirstName();
  }
  if (targetName === "lname") {
    checkLastName();
  }
  if (targetName === "isbroker") {
    checkIsBroker();
  }
  if (targetName === "phone") {
    checkPhone();
  }
  if (targetName === "country") {
    checkCountry();
  }
  if(targetName === "howhear") {
    checkHowHear();
  }
  return errors;
};

export default validateForm;
