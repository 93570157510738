import React, { FC, useRef, useState } from "react";
import {
  ContactFormWrapper,
  ContactBodyContainer,
  ContactSuccessHeader,
  Logo,
  Contacts
} from "./index.styled";
import ContactForm from "./contact-form";
import IntroLogo from "../../../assets/images/intro_logo_dark.svg";
interface IContactBodyProps {
  setOpenLegal?: () => void
}

const ContactBody: FC<IContactBodyProps> = () => {
  const contactWrapperRef = useRef<HTMLDivElement>(null);
  const contactBodyRef = useRef<HTMLDivElement>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const showSuccess = () => {
    setSubmitted(true);
  };

  return (
    <ContactBodyContainer ref={contactWrapperRef}>
      <Logo to={'/'}><img src={IntroLogo} alt="" /></Logo>
      <ContactFormWrapper ref={contactBodyRef}>
        {submitted ? (
          <ContactSuccessHeader>
            THANK YOU FOR YOUR INTEREST IN <br />
            THE RITZ-CARLTON RESIDENCES, POMPANO BEACH.
          </ContactSuccessHeader>
        ) : (
          <>
            <Contacts>
              <p>Site Address: 1380 S. Ocean Boulevard, <span>Pompano Beach, Florida 33062</span></p>
              <p>Sales Lounge: 1201 S. Ocean Boulevard, Suite 1-2-3, <span>Pompano Beach, Florida 33062</span></p>
              <p><a href="tel:7542181868"><u className="InfinityNumber clickable">754 218 1868</u></a> <b>|</b> <span><a href="mailto:info@theresidencespompanobeach.com">info@theresidencespompanobeach.com</a></span></p>
            </Contacts>
            <ContactForm showSuccess={showSuccess} />
          </>
        )}
      </ContactFormWrapper>
    </ContactBodyContainer>
  );
};

export default ContactBody;
